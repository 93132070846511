/*--------------------------------------------------------------
#0.6 wpo-news-letter-part
--------------------------------------------------------------*/
.wpo-news-letter-section,
.wpo-news-letter-section-2 {
    text-align: center;
    position: relative;
    z-index: 1;
}

.wpo-news-letter-section:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    content: "";
    background: #f9f9fc;
    z-index: -1;
}

.wpo-news-letter-wrap {
    max-width: 970px;
    margin: auto;
    box-shadow: 0px 4px 9.5px 0.5px rgba(53, 53, 53, 0.1);
    padding: 60px;
    background: #f9fdfe;
}


.wpo-news-letter-section-2 {
    background: #fcfcfc;
}

@media (max-width: 991px) {

    .wpo-news-letter-section,
    .wpo-news-letter-section-2 {
        padding: 0;
    }
}

.wpo-news-letter-section h3,
.wpo-news-letter-section-2 h3 {
    font-size: 30px;
    font-size: 1.875rem;
    font-weight: bold;
    margin: 0 0 0.5em;
    line-height: 1.2em;
    font-family: 'Conv_Now-Bold';
}

.wpo-news-letter-section p,
.wpo-news-letter-section-2 p {
    margin: 0 0 2em;
    color: #777777;
}

@media(max-width: 1024px) {

    .wpo-news-letter-section h3,
    .wpo-news-letter-section-2 h3 {
        font-size: 28px;
        line-height: 34px;
    }
}

.wpo-news-letter-section-2 h3 {
    color: #062265;
}

@media (max-width: 991px) {

    .wpo-news-letter-section h3,
    .wpo-news-letter-section-2 h3 {
        font-size: 25px;
        font-size: 1.5625rem;
    }
}

@media (max-width: 767px) {

    .wpo-news-letter-section h3,
    .wpo-news-letter-section-2 h3 {
        font-size: 18px;
    }
}

.wpo-news-letter-section form,
.wpo-news-letter-section-2 form {
    max-width: 425px;
    margin: 0 auto;
}

.wpo-news-letter-section form>div,
.wpo-news-letter-section-2 form>div {
    position: relative;
}

.wpo-news-letter-section form>div input,
.wpo-news-letter-section-2 form>div input {
    height: 55px;
    border: 0;
    outline: 0;
    border-radius: 55px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 15px 15px 15px 25px;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
}

@media (max-width: 991px) {

    .wpo-news-letter-section form>div input,
    .wpo-news-letter-section-2 form>div input {
        height: 50px;
    }
}

.wpo-news-letter-section form>div button,
.wpo-news-letter-section-2 form>div button {
    background: linear-gradient(to left, #00a7d5, #00ded8);
    background: -webkit-linear-gradient(to left, #00a7d5, #00ded8);
    background: -moz-linear-gradient(to left, #00a7d5, #00ded8);
    background: -o-linear-gradient(to left, #00a7d5, #00ded8);
    background: -ms-linear-gradient(to left, #00a7d5, #00ded8);
    background: -webkit-gradient(linear, left top, right top, from(#00a7d5), to(#00ded8));
    background: linear-gradient(to left, #00a7d5, #00ded8);
    width: 150px;
    height: 45px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    border: 0;
    outline: 0;
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 45px;
    line-height: 45px;
}

@media (max-width: 991px) {

    .wpo-news-letter-section form>div button,
    .wpo-news-letter-section-2 form>div button {
        width: 120px;
        height: 40px;
    }
}
